.page{
    width: 100vw;
    min-height: 100vh;
    background-image: url('https://killnet.babyloser.com/static/media/bg-nft.ae47f6ff.png');
    // z-index: 999;
   
        position: absolute;
        // height: 160%;
        // width: 100%;
        top: 0px;
        left: 0px;
        object-fit: cover;
        overflow: hidden;
        // opacity: 0;
        // z-index: -1;
  
}
// .jaCAEK{
//     display: none !important;;
// }
.hknxqB {
    margin: 140px auto;
    margin-bottom: 0;
    max-width: 960px;
    
}
.dICrqx {
    width: 100%;
}
.dICrqx .ant-menu-horizontal {
    border-width: 1px;
}
.dICrqx .ant-menu {
    font-weight: bold;
    background: transparent;
}
.ant-menu-horizontal {
    line-height: 46px;
    border: 0;
    border-bottom: 1px solid rgba(37,225,174,.7);
    box-shadow: none;
}
.ant-menu-overflow {
    display: flex;
}
.ant-menu {
    box-sizing: border-box;
    font-variant: tabular-nums;
    line-height: 1.5715;
    -webkit-font-feature-settings: "tnum", "tnum";
    font-feature-settings: "tnum", "tnum";
    margin: 0;
    padding: 0;
    color: hsla(0,0%,100%,.85);
    font-size: 14px;
    line-height: 0;
    text-align: left;
    list-style: none;
    background: rgba(1,0,1,.9);
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
    transition: background .3s, width .3s cubic-bezier(.2, 0, 0, 1) 0s;
}
// .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
//     color: #25e1ae;
// }
.tab-item--activated{
    color: #25e1ae;
}
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    margin-top: -1px;
    margin-bottom: 0;
    padding: 0 20px;
}
.dVNune .tab-item {
    font-family: "Neptune Lander", quantico, sans-serif;
    font-size: 1.5em;
}
.ant-menu-horizontal>.ant-menu-item, .ant-menu-horizontal>.ant-menu-submenu {
    position: relative;
    top: 1px;
    display: inline-block;
    vertical-align: bottom;
}
.ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu-title {
    transition: border-color .3s, background .3s;
}
.ant-menu-item, .ant-menu-submenu-title {
    position: relative;
    display: block;
    margin: 0;
    padding: 0 20px;
    white-space: nowrap;
    cursor: pointer;
    transition: border-color .3s, background .3s, padding .3s cubic-bezier(.645, .045, .355, 1);
}
.ant-menu-overflow-item {
    flex: none;
}
.dICrqx .ant-menu {
    font-weight: bold;
    background: transparent;
}
.ant-menu-horizontal {
    line-height: 46px;
    border: 0;
    border-bottom: 1px solid rgba(37,225,174,.7);
    box-shadow: none;
}
.ant-menu {
    box-sizing: border-box;
    font-variant: tabular-nums;
    line-height: 1.5715;
    -webkit-font-feature-settings: "tnum", "tnum";
    font-feature-settings: "tnum", "tnum";
    margin: 0;
    padding: 0;
    color: hsla(0,0%,100%,.85);
    font-size: 18px;
    line-height: 0;
    text-align: left;
    list-style: none;
    background: rgba(1,0,1,.9);
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
    transition: background .3s, width .3s cubic-bezier(.2, 0, 0, 1) 0s;
}
.ant-menu-horizontal>.ant-menu-item:after, .ant-menu-horizontal>.ant-menu-submenu:after {
    position: absolute;
    right: 20px;
    bottom: 0;
    left: 20px;
    border-bottom: 2px solid transparent;
    transition: border-color .3s cubic-bezier(.645, .045, .355, 1);
    content: "";
}
.dVNune .tab-item {
    font-family: "Neptune Lander", quantico, sans-serif;
    font-size: 40px;
}
.center, .mr-auto {
    margin-right: auto;
}
.center {
    margin-left: auto;
}
.mw7 {
    max-width: 48rem;
}
.mw-100 {
    max-width: 100%;
}
.display{
    display: none !important;
}
.TcOjT {
    display: flex;
}
.TcOjT button {
    border: none;
    background: transparent;
}
.TcOjT span {
    color: rgb(37, 225, 174);
}

.center, .mr-auto {
    margin-right: auto;
}
.center {
    margin-left: auto;
}
.mt2 {
    margin-top: .5rem;
}
.ant-btn-primary {
    color: #0f0f0f;
    border-color: #25e1ae;
    background: #25e1ae;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 0 rgba(0,0,0,.045);
}
.ant-btn, .ant-btn:active, .ant-btn:focus {
    outline: 0;
}
.ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    box-shadow: 0 2px 0 rgba(0,0,0,.015);
    cursor: pointer;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: hsla(0,0%,100%,.85);
    border: 1px solid #d9d9d9;
    background: rgba(1,0,1,.9);
}
button, select {
    text-transform: none;
}
button, select {
    text-transform: none;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
button, input, optgroup, select, textarea {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
}
*, :after, :before {
    box-sizing: border-box;
}
.TcOjT .ant-btn:hover {
    background: rgba(37, 225, 174, 0.1);
}
.ant-btn-primary:focus, .ant-btn-primary:hover {
    // color: #0f0f0f;
    border-color: #4cedbd;
    background: #4cedbd;
}
.pa3 {
    padding: 1rem;
    font-size: 20px;
}
.ant-row-space-around {
    justify-content: space-around;
}
.ant-row, .ant-row:after, .ant-row:before {
    display: flex;
    justify-content: center;
}
.ant-row {
    flex-flow: row wrap;
}
@media (max-width: 992px) {
    .ant-col-lg-6 {
        display: block;
        flex: 0 0 25%;
        max-width: 25%;
    }
}
@media (max-width: 768px) {
    .ant-col-md-8 {
        display: block;
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
    }
}
@media (max-width: 576px) {
    .ant-col-sm-12 {
        display: block;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
// .ant-col-xs-24 {
//     display: block;
//     flex: 0 0 100%;
//     max-width: 100%;
// }
.ant-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
}
.kSxRYG {
    margin: 8px;
    text-align: center;
    position: relative;
}
.kSxRYG video {
    width: 200px;
    height: 257px;
}
.ant-row, .ant-row:after, .ant-row:before {
    display: flex;
    justify-content: flex-start;
}
.ant-row {
    flex-flow: row wrap;
}
.kSxRYG {
    margin: 8px;
    text-align: center;
    position: relative;
}
.kSxRYG video {
    width: 200px;
    height: 257px;
}
.CkOuA .text-bold {
    color: rgb(37, 225, 174);
    font-weight: bold;
}
.f2 {
    font-size: 2.25rem;
}
.mt3 {
    margin-top: 1rem;
}
.ant-row {
    flex-flow: row wrap;
}
body {
    color: hsla(0,0%,100%,.85);
    font-size: 14px !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-variant: tabular-nums !important;
    line-height: 1.5715rem !important;
    background-color: #000000;
    -webkit-font-feature-settings: "tnum", "tnum" !important;
    font-feature-settings: "tnum", "tnum" !important;
}

.bgimgs{
    width: 50%;
    height: 300px;
}
.bgimgs img{
    width: 50%;
    // height: 50%;
    height: 500px;
}